import service from '@/services/modules/comercial/control_fise'

export default {
  CONTROL_FISE_FIND_ALL(_, params) {
    return new Promise((resolve, reject) => {
      service.findAll(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  CONSULTA_DNI_FIND_ALL(_, params) {
    return new Promise((resolve, reject) => {
      service.findAllDni(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  CONTROL_FISE_UPDATE(_, params) {
    return new Promise((resolve, reject) => {
      service.update(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  CONTROL_FISE_CREATE(_, params) {
    return new Promise((resolve, reject) => {
      service.create(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  CONTROL_FISE_MASIVO_CREATE(_, params) {
    return new Promise((resolve, reject) => {
      service.createMasivo(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  CONTROL_FISE_SALDO_ALL(_, params) {
    return new Promise((resolve, reject) => {
      service.findAllSaldo(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  CONTROL_FISE_PROCESADO_MASIVO_CREATE(_, params) {
    return new Promise((resolve, reject) => {
      service.createValePecesado(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
}
