export default [
  {
    path: '/app/cobranza/credito',
    name: 'rt-cobranza-credito',
    component: () => import('@/views/erp/cobranza/credito/CreditoList.vue'),
    meta: {
      resource: 'rt-cobranza-credito',
      pageTitle: 'Creditos',
      breadcrumb: [
        {
          text: 'Cobranzas',
        },
        {
          text: 'Creditos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/cobranza/creditofise',
    name: 'rt-cobranza-creditofise',
    component: () => import('@/views/erp/cobranza/credito/ControlFise.vue'),
    meta: {
      resource: 'rt-cobranza-creditofise',
      pageTitle: 'Control Fise',
      breadcrumb: [
        {
          text: 'Cobranzas',
        },
        {
          text: 'Control Fise',
          active: true,
        },
      ],
    },
    props: true,
  },
  {
    path: '/app/cobranza/saldocreditofise',
    name: 'rt-cobranza-saldofise',
    component: () => import('@/views/erp/cobranza/credito/SaldoControlFise.vue'),
    meta: {
      resource: 'rt-cobranza-saldofise',
      pageTitle: 'Saldo Fise',
      breadcrumb: [
        {
          text: 'Cobranzas',
        },
        {
          text: 'Saldo Fise',
          active: true,
        },
      ],
    },
  },
]
