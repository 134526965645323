import apiCall from '../../index'

const apiService = '/api-erpunigas/api/v1/reporte'
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findAll(params) {
    // const pertiodo = params.periodo === undefined ? '' : `&fecha_fin=${params.fecha_fin}`
    const query = `/venta?limit=${params.limit}&page=${params.page}&query=${params.query}&sortBy=${params.sortBy}&fecha_fin=${params.fechaFin}&fecha_inicio=${params.fechaInicio}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  findAllDetallado(params) {
    // const pertiodo = params.periodo === undefined ? '' : `&fecha_fin=${params.fecha_fin}`
    const query = `/venta_detallado?limit=${params.limit}&page=${params.page}&query=${params.query}&sortBy=${params.sortBy}&fecha_fin=${params.fechaFin}&fecha_inicio=${params.fechaInicio}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
