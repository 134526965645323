export default [
  {
    path: '/app/reporte/venta',
    name: 'rt-reporte-venta',
    component: () => import('@/views/erp/reporte/venta/ReporteVentaList.vue'),
    meta: {
      resource: 'rt-reporte-venta',
      pageTitle: 'Reporte de Ventas',
      breadcrumb: [
        {
          text: 'Reportes',
        },
        {
          text: 'Reporte de Ventas',
          active: true,
        },
      ],
    },
  },
]
