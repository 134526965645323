// import actions
import creditoAction from './actions/credito'
// import getters

// import mutations

// import states

const actions = { ...creditoAction }

const getters = { }

const mutations = { }

const state = { }

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
