// import actions
import ventaAction from './actions/venta'
// import getters

// import mutations

// import states

const actions = { ...ventaAction }

const getters = { }

const mutations = { }

const state = { }

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
