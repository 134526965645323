import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import Auth from './modules/auth/index'
import Rrhh from './modules/rrhh'
import Comercial from './modules/comercial'
import Multimedia from './modules/multimedia/index'
import Catalogo from './modules/catalogo'
import Seguridad from './modules/seguridad'
import Helpers from './modules/helpers'
import Inventario from './modules/inventario'
import Vigilancia from './modules/vigilancia'
import Facturacion from './modules/facturacion'
import Logistica from './modules/logistica'
import Cobraza from './modules/cobranza'
import Reporte from './modules/reporte'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    auth: Auth,
    rrhh: Rrhh,
    vigilancia: Vigilancia,
    comercial: Comercial,
    multimedia: Multimedia,
    catalogo: Catalogo,
    inventario: Inventario,
    facturacion: Facturacion,
    seguridad: Seguridad,
    helpers: Helpers,
    logistica: Logistica,
    cobranza: Cobraza,
    reporte: Reporte,
  },
  strict: process.env.DEV,
})
