// import actions
import clienteAction from './actions/cliente'
import dashboardAction from './actions/dashboard'
import productoAction from './actions/producto'
import puntoventaAction from './actions/puntoventa'
import controlfiseAction from './actions/control_fise'
// import getters

// import mutations

// import states

const actions = { ...clienteAction, ...dashboardAction, ...productoAction, ...puntoventaAction, ...controlfiseAction }

const getters = { }

const mutations = { }

const state = { }

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
