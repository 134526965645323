import service from '@/services/modules/reporte/venta'

export default {
  REPORTE_VENTAS_FIND_ALL(_, params) {
    return new Promise((resolve, reject) => {
      service.findAll(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  REPORTE_VENTAS_DETALLADO_FIND_ALL(_, params) {
    return new Promise((resolve, reject) => {
      service.findAllDetallado(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
}
