export default [
  {
    path: '/app/comercial/dashboard-gerencia',
    name: 'rt-comercial-tablero-gerencia',
    component: () => import('@/views/erp/comercial/dashboard/gerencia/GerenciaMain.vue'),
    meta: {
      resource: 'rt-comercial-tablero-gerencia',
      pageTitle: 'Tablero de Indicadores Gerencial',
      breadcrumb: [
        {
          text: 'Comercial',
        },
        {
          text: 'Tablero de Indicadores',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/comercial/cliente',
    name: 'rt-comercial-clientes',
    component: () => import('@/views/erp/comercial/cliente/ClienteList.vue'),
    meta: {
      resource: 'rt-comercial-clientes',
      pageTitle: 'Cliente',
      breadcrumb: [
        {
          text: 'Comercial',
        },
        {
          text: 'Cliente',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/comercial/producto',
    name: 'rt-comercial-productos',
    component: () => import('@/views/erp/comercial/producto/ProductoList.vue'),
    meta: {
      resource: 'rt-comercial-productos',
      pageTitle: 'Productos',
      breadcrumb: [
        {
          text: 'Comercial',
        },
        {
          text: 'Productos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/comercial/puntoventa',
    name: 'rt-comercial-puntoventa',
    component: () => import('@/views/erp/comercial/puntoventa/PuntoVentaList.vue'),
    meta: {
      resource: 'rt-comercial-puntoventa',
      pageTitle: 'Punto de Venta',
      breadcrumb: [
        {
          text: 'Comercial',
        },
        {
          text: 'Lista de Ventas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/comercial/nuevaventa',
    name: 'rt-comercial-nuevaventa',
    component: () => import('@/views/erp/comercial/puntoventa/PuntoVentaForm.vue'),
    meta: {
      resource: 'rt-comercial-nuevaventa',
      pageTitle: 'Nueva Venta',
      breadcrumb: [
        {
          text: 'Comercial',
        },
        {
          text: 'Nueva Ventas',
          active: true,
        },
      ],
    },
  },

  {
    path: '/app/comercial/guia',
    name: 'rt-comercial-guiaremision',
    component: () => import('@/views/erp/comercial/guiaremision/GuiaRemision.vue'),
    meta: {
      resource: 'rt-comercial-guiaremision',
      pageTitle: 'Guias',
      breadcrumb: [
        {
          text: 'Lista de Guias',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/comercial/guiaform',
    name: 'rt-comercial-guiaremisionform',
    component: () => import('@/views/erp/comercial/guiaremision/GuiaRemisionForm.vue'),
    meta: {
      resource: 'rt-comercial-guiaremisionform',
      pageTitle: 'Guias',
      breadcrumb: [
        {
          text: 'Nueva Guia',
          active: true,
        },
      ],
    },
  },

  {
    path: '/app/comercial/compra',
    name: 'rt-comercial-compra',
    component: () => import('@/views/erp/comercial/compra/Compra.vue'),
    meta: {
      resource: 'rt-comercial-compra',
      pageTitle: 'Compras',
      breadcrumb: [
        {
          text: 'Lista de Compras',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/comercial/compraform',
    name: 'rt-comercial-compraform',
    component: () => import('@/views/erp/comercial/compra/CompraForm.vue'),
    meta: {
      resource: 'rt-comercial-compraform',
      pageTitle: 'Compras',
      breadcrumb: [
        {
          text: 'Nueva Compras',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/comercial/control_fise',
    name: 'rt-comercial-controlfise',
    component: () => import('@/views/erp/comercial/control_fise/ControlFiseList.vue'),
    meta: {
      resource: 'rt-comercial-controlfise',
      pageTitle: 'Lista de Ventas',
      breadcrumb: [
        {
          text: 'Control Fise',
          active: true,
        },
      ],
    },
    props: true,
  },

]
