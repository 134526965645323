import apiCall from '../../index'

const apiService = '/api-erpunigas/api/v1/facturacion'
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findAll(params) {
    const query = `/control_fise?tipo=${params.tipo}&venta=${params.venta}&limit=${params.limit}&page=${params.page}&query=${params.query}&sortBy=${params.sortBy}&cliente_id=${params.cliente_id}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  findAllDni(params) {
    const query = `/cliente_dni?dni=${params.documento}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  update(model) {
    const query = '/control_fise'
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .put(apiService + query, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  create(model) {
    const query = '/control_fise'
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService + query, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  createMasivo(params) {
    const query = `/control_fise_masivo?cliente_id=${params.cliente_id}&cantidad=${params.cantidad}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  findAllSaldo(params) {
    const query = `/saldo_control_fise?limit=${params.limit}&page=${params.page}&query=${params.query}&cliente_id=${params.cliente_id}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  createValePecesado(model) {
    const query = '/control_fise_procesado'
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService + query, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
